exports.components = {
  "component---node-modules-lullabot-gatsby-theme-adr-src-pages-404-tsx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-pages-404-tsx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-pages-adrs-tsx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/pages/adrs.tsx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-pages-adrs-tsx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20210608-record-architecture-decisions-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20210608-record-architecture-decisions.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20210608-record-architecture-decisions-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20210609-environment-indicator-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20210609-environment-indicator.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20210609-environment-indicator-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20210705-use-log-4-brains-to-manage-the-adrs-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20210705-use-log4brains-to-manage-the-adrs.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20210705-use-log-4-brains-to-manage-the-adrs-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20210729-stage-file-proxy-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20210729-stage-file-proxy.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20210729-stage-file-proxy-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20210924-drupal-build-steps-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20210924-drupal-build-steps.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20210924-drupal-build-steps-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20211006-avoid-command-aliases-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20211006-avoid-command-aliases.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20211006-avoid-command-aliases-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20211026-one-time-login-first-drupal-user-account-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20211026-one-time-login-first-drupal-user-account.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20211026-one-time-login-first-drupal-user-account-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20211026-use-settings-not-splits-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20211026-use-settings-not-splits.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20211026-use-settings-not-splits-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20211201-use-strict-types-in-php-code-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20211201-use-strict-types-in-php-code.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20211201-use-strict-types-in-php-code-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20211207-ddev-locals-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20211207-ddev-locals.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20211207-ddev-locals-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20211212-config-status-check-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20211212-config-status-check.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20211212-config-status-check-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220112-type-hints-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220112-type-hints.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220112-type-hints-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220119-composer-patches-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220119-composer-patches.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220119-composer-patches-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220207-php-unit-tests-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220207-php-unit-tests.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220207-php-unit-tests-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220208-naming-fields-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220208-naming-fields.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220208-naming-fields-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220320-prevent-language-change-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220320-prevent-language-change.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220320-prevent-language-change-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220425-hide-untranslatable-fields-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220425-hide-untranslatable-fields.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220425-hide-untranslatable-fields-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220426-use-dedicated-accounts-service-integrations-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220426-use-dedicated-accounts-service-integrations.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220426-use-dedicated-accounts-service-integrations-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220429-composer-exit-failure-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220429-composer-exit-failure.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220429-composer-exit-failure-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220429-composer-patch-files-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220429-composer-patch-files.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220429-composer-patch-files-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220429-composer-patches-inline-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220429-composer-patches-inline.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220429-composer-patches-inline-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220429-composer-patchlevel-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220429-composer-patchlevel.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220429-composer-patchlevel-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220503-use-type-tray-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220503-use-type-tray.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220503-use-type-tray-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220504-use-simple-add-more-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220504-use-simple-add-more.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220504-use-simple-add-more-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220511-use-inclusive-language-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220511-use-inclusive-language.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220511-use-inclusive-language-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220519-use-claro-as-admin-theme-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220519-use-claro-as-admin-theme.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220519-use-claro-as-admin-theme-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220621-gatsby-theme-adr-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220621-gatsby-theme-adr.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220621-gatsby-theme-adr-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220622-1-do-not-add-metatags-to-node-form-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220622-1-do-not-add-metatags-to-node-form.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220622-1-do-not-add-metatags-to-node-form-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220622-use-css-logical-properties-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220622-use-css-logical-properties.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220622-use-css-logical-properties-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220705-use-typescript-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220705-use-typescript.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220705-use-typescript-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220721-organize-stylesheets-using-smacss-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220721-organize-stylesheets-using-smacss.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220721-organize-stylesheets-using-smacss-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220816-use-js-strict-mode-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220816-use-js-strict-mode.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220816-use-js-strict-mode-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220906-identical-cache-backends-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220906-identical-cache-backends.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220906-identical-cache-backends-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220920-git-branch-naming-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20220920-git-branch-naming.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20220920-git-branch-naming-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20221205-render-entities-with-view-modes-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20221205-render-entities-with-view-modes.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20221205-render-entities-with-view-modes-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20230309-use-typed-entity-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20230309-use-typed-entity.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20230309-use-typed-entity-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20230804-javascript-loading-defaults-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20230804-javascript-loading-defaults.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20230804-javascript-loading-defaults-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20230929-drupal-build-steps-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20230929-drupal-build-steps.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20230929-drupal-build-steps-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20231027-javascript-loading-defaults-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20231027-javascript-loading-defaults.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20231027-javascript-loading-defaults-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20231110-field-order-as-in-page-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20231110-field-order-as-in-page.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20231110-field-order-as-in-page-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20240212-do-not-use-placeholders-forms-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20240212-do-not-use-placeholders-forms.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20240212-do-not-use-placeholders-forms-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20240221-use-svg-icons-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20240221-use-svg-icons.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20240221-use-svg-icons-mdx" */),
  "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20240820-project-docs-mdx": () => import("./../../../node_modules/@lullabot/gatsby-theme-adr/src/templates/AdrTemplate.tsx?__contentFilePath=/home/runner/work/architecture/architecture/adrs/20240820-project-docs.mdx" /* webpackChunkName: "component---node-modules-lullabot-gatsby-theme-adr-src-templates-adr-template-tsx-content-file-path-adrs-20240820-project-docs-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

