module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9d3f0f67c76cc7d81b82c6c8affa92dd"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":"<svg aria-hidden=\"true\" height=\"20\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"20\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>","removeAccents":true,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/@lullabot/gatsby-theme-adr/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"adrs","siteMetadata":{"siteUrl":"https://architecture.lullabot.com","title":"Lullabot's Architecture Decision Records","image":"/icons/icon-512x512.png","menuLinks":[{"name":"Home","uri":"/","iconName":"HomeIcon"},{"name":"All ADRs","uri":"/adrs","iconName":"CollectionIcon"},{"name":"Drupal ADRs","uri":"/adrs/drupal","iconName":"AnnotationIcon"},{"name":"DevOps ADRs","uri":"/adrs/devops","iconName":"AnnotationIcon"}],"socialLinks":[{"name":"RSS","uri":"https://www.lullabot.com/rss/feed","iconName":"rss"},{"name":"GitHub","uri":"https://www.github.com/Lullabot"},{"name":"Twitter","uri":"https://www.twitter.com/lullabot"},{"name":"LinkedIn","uri":"https://www.linkedin.com/company/lullabot"},{"name":"YouTube","uri":"https://www.youtube.com/c/lullabot"},{"name":"Terms of Service","uri":"https://www.lullabot.com/terms"}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
